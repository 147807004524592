export default [
  {
    path: '/blog',
    name: 'HomeBlog',
    component: () =>
      import(/* webpackChunkName: "login" */ './views/HomeBlog.vue'),
    meta: {
      title: 'Blog',
      inNavWidgets: true,
      icon: 'message-bulleted'
    }
  },
  {
    path: '/blog/create',
    name: 'CreatePost',
    component: () =>
      import(/* webpackChunkName: "login" */ './views/Create.vue'),
    meta: {
      title: 'Create Post',
      icon: 'message-plus',
      navBack: true
    }
  },
  {
    path: '/blog/posts/:id',
    name: 'PostDetails',
    component: () =>
      import(/* webpackChunkName: "login" */ './views/Details.vue'),
    props: true,
    meta: {
      navBack: true
    }
  },
  {
    path: '/blog/tags/:tag',
    name: 'Tags',
    component: () => import(/* webpackChunkName: "login" */ './views/Tags.vue'),
    props: true,
    meta: {
      navBack: true
    }
  }
]
