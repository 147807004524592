<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12" sm="6">
        <basic-card>
          <template #title>Badged Button</template>
          <template #subtitle>Default badges, with no options</template>
          <v-row
            no-gutters
            class="d-flex align-content-center justify-space-around flex-wrap"
          >
            <app-badge
              v-for="btn in data"
              :key="`btn_dark${btn.color}`"
              class="mb-2 mx-1"
              :value="btn.notification"
            >
              <app-btn dark :color="btn.color" @click="sayInfo(btn.color)"
                >{{ btn.color }}
              </app-btn>
            </app-badge>
          </v-row>
        </basic-card>
      </v-col>
      <v-col>
        <basic-card>
          <template #title>Botom Dot Badges</template>
          <template #subtitle>Buttom with botom and dot badges</template>
          <v-row
            no-gutters
            class="d-flex align-content-center justify-space-around flex-wrap"
          >
            <app-badge
              v-for="btn in data"
              :key="`btn_dark${btn.color}`"
              options="bottom--dot"
              class="mb-2 mx-1"
              :value="btn.notification"
              :color="data[$utils.randomInt(0, 7)].color"
            >
              <app-btn dark :color="btn.color" @click="sayInfo(btn.color)"
                >{{ btn.color }}
              </app-btn>
            </app-badge>
          </v-row>
        </basic-card>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" sm="6">
        <basic-card>
          <template #title>Badged Icons</template>
          <template #subtitle>Icons with default value badge</template>
          <v-row no-gutters align="center" justify="space-around">
            <app-badge
              v-for="icon in data"
              :key="icon.icon"
              :color="icon.color"
              :value="icon.notification"
            >
              <app-icon color="blue" :icon="icon.icon" />
            </app-badge>
          </v-row>
        </basic-card>
      </v-col>
      <v-col cols="12" sm="6">
        <basic-card>
          <template #title>Dot Badged Icons</template>
          <template #subtitle>Icons with dot badge</template>
          <v-row no-gutters align="center" justify="space-around">
            <app-badge
              v-for="icon in data"
              :key="icon.icon"
              options="dot"
              :color="icon.color"
              :value="icon.notification"
            >
              <app-icon color="secondary" :icon="icon.icon" />
            </app-badge>
          </v-row>
        </basic-card>
      </v-col>
    </v-row>
    <v-row no-gutters justify="center">
      <v-col cols="12" sm="8">
        <basic-card>
          <template #title>Badged Text</template>
          <template #subtitle>Default typography with badges</template>
          <div v-for="item in typography" :key="item.name">
            <app-badge
              :color="data[$utils.randomInt(0, 7)].color"
              :options="$utils.randomBoolean() ? 'dot' : 'bottom'"
              :value="$utils.randomInt(0, 20)"
            >
              <div :class="`text-${item.tag}`">{{ item.text }}</div>
            </app-badge>
          </div>
        </basic-card>
      </v-col>
    </v-row>

    <v-card>
      <v-card-title>Text with badges</v-card-title>
      <v-card-text> </v-card-text>
      <v-divider></v-divider>
    </v-card>
    <app-badge color="green" :value="9">
      <app-btn>Botão</app-btn>
    </app-badge>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { BasicCard } from '@/components/layout/cards'
export default {
  name: 'Badges',

  components: {
    BasicCard
  },

  computed: {
    ...mapState({
      data: (state) => state.examples.data,
      typography: (state) => state.examples.typography
    })
  },

  methods: {
    sayInfo(info) {
      console.log(info.toUpperCase())
    }
  }
}
</script>

<style></style>
