import { ref } from '@vue/composition-api'
import { fbFirestore } from '@/plugins/firebase'
import utils from '@/utils/utils'

const useDocument = (collection, id) => {
  let error = ref(null)
  let isPending = ref(false)
  let docRef = fbFirestore.collection(collection).doc(id)

  const deleteDoc = async () => {
    isPending.value = true
    error.value = null

    try {
      const res = await docRef.delete()
      isPending.value = false
      return res
    } catch (err) {
      console.log(err.message)
      isPending.value = false
      error.value = 'Não foi possível exluir o documento.'
    }
  }
  const updateDoc = async (data) => {
    isPending.value = true
    error.value = null

    try {
      await utils.delay(500)
      const res = await docRef.update(data)
      isPending.value = false
      return res
    } catch (err) {
      console.log(err.message)
      isPending.value = false
      error.value = 'Não foi possível editar o documento.'
    }
  }

  return { error, isPending, deleteDoc, updateDoc }
}

export default useDocument
