<template>
  <nav-list>
    <nav-list-item reverse name="Home" title="Home" icon="home" />
    <app-divider />
    <nav-list-group title="Widgets" icon="widgets">
      <nav-list-item
        v-for="route in widgetRoutes"
        :key="route.id"
        :name="route.name"
        :title="route.title"
        :icon="route.icon"
      />
    </nav-list-group>
    <app-divider />
    <nav-list-group title="Examples" icon="vector-square">
      <nav-list-item
        v-for="route in exampleRoutes"
        :key="route.id"
        :name="route.name"
        :title="route.title"
        :icon="route.icon"
      />
    </nav-list-group>
  </nav-list>
</template>

<script>
import { computed } from '@vue/composition-api'
import utils from '@/utils/utils'
import NavList from '@/components/layout/nav/NavList'
import NavListGroup from '@/components/layout/nav/NavListGroup'
import NavListItem from '@/components/layout/nav/NavListItem'

export default {
  name: 'NavDrawerItems',

  components: {
    NavList,
    NavListGroup,
    NavListItem
  },

  setup() {
    const widgetRoutes = computed(() => utils.filteredRoutes('inNavWidgets'))
    const exampleRoutes = computed(() => utils.filteredRoutes('inNavExample'))

    return {
      widgetRoutes,
      exampleRoutes
    }
  }
}
</script>

<style></style>
