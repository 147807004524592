<template>
  <v-chip class="mx-1" v-bind="[chipsDefaults, $attrs]" v-on="$listeners">
    <app-icon v-if="lIcon" left :icon="lIcon"></app-icon>
    {{ `${hashtag}${title}` }}
    <app-icon v-if="rIcon" right :icon="rIcon"></app-icon>
  </v-chip>
</template>

<script>
import { computed } from '@vue/composition-api'
export default {
  name: 'AppChip',

  props: {
    title: {
      type: String,
      required: true
    },
    hashtag: {
      type: String,
      default: ''
    },
    lIcon: {
      type: [Boolean, String],
      default: false
    },
    rIcon: {
      type: [Boolean, String],
      default: false
    }
  },

  setup() {
    const chipsDefaults = computed(() => ({
      color: 'primary'
    }))

    return { chipsDefaults }
  }
}
</script>

<style></style>
