<template>
  <app-text-field
    v-bind="[passwordFieldDefaults, $attrs]"
    :type="!showPassword ? 'password' : 'text'"
    :icon="icon"
    v-on="$listeners"
    @input="(v) => $emit('input', v)"
  >
    <template #append>
      <app-icon
        color="default"
        :icon="showPassword ? 'eye' : 'eye-off'"
        @click="showPassword = !showPassword"
      ></app-icon>
    </template>
  </app-text-field>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
export default {
  name: 'TextFieldPassword',

  props: {
    icon: {
      type: String,
      default: 'lock'
    }
  },

  setup() {
    const showPassword = ref(false)

    const passwordFieldDefaults = computed(() => ({
      placeholder: 'Senha'
    }))

    return { showPassword, passwordFieldDefaults }
  }
}
</script>

<style></style>
