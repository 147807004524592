<template>
  <v-list nav dense flat>
    <slot />
  </v-list>
</template>

<script>
export default {
  name: 'NavList'
}
</script>
