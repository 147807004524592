export default {
  tasks: [
    {
      id: '3209397d-8a04-4a46-a839-416b51f48673',
      title: 'Estudar',
      description: 'Aqui vai a descrição da tarefa',
      isDone: false
    },
    {
      id: 'b884302e-fd23-4ba9-bfaa-b78e4279da89',
      title: 'Lavar a louça',
      description: 'Aqui vai a descrição da tarefa',
      isDone: true
    },
    {
      id: 'a92921d1-4a97-4d62-b88d-e484bc1e023c',
      title: 'Comprar Pão',
      description: 'Aqui vai a descrição da tarefa',
      isDone: false
    },
    {
      id: '3c8d17f5-8161-4b03-b982-befdf4712264',
      title: 'Fazer o almoço',
      description: 'Aqui vai a descrição da tarefa',
      isDone: true
    },
    {
      id: '5328cd16-4821-4e94-b09d-fac820ab9253',
      title: 'Imprimir Relatórios',
      description: 'Aqui vai a descrição da tarefa',
      isDone: false
    },
    {
      id: '700c2bda-3918-4a04-8ec5-cec2e12ce25a',
      title: 'Tratar dos tubarões',
      description: 'Aqui vai a descrição da tarefa',
      isDone: true
    },
    {
      id: '9220fcb7-891f-4ccf-a6ec-1b63350454d7',
      title: 'Escorregar no Escorregador',
      description: 'Aqui vai a descrição da tarefa',
      isDone: false
    }
  ]
}
