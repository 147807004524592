<template>
  <v-dialog
    v-model="deleteDialog"
    max-width="500px"
    @click:outside="closeDialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <app-icon
        :loading="loading"
        :color="color"
        :icon="icon"
        v-bind="attrs"
        v-on="on"
      ></app-icon>
    </template>

    <v-card>
      <v-card-title> Excluir {{ itemName }}? </v-card-title>

      <v-card-actions>
        <v-spacer></v-spacer>
        <app-btn color="primary" text @click="closeDialog">Cancelar</app-btn>
        <app-btn color="error" text @click="handleDelete">Excluir</app-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import { ref } from '@vue/composition-api'
import useDialogs from './useDialogs'

export default {
  name: 'DeleteDialog',

  props: {
    itemName: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'error'
    },
    icon: {
      type: String,
      default: 'delete'
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  setup(props, { emit }) {
    // const deleteDialog = ref(false)
    const { dialog: deleteDialog, handleClose: closeDialog } = useDialogs()

    const handleDelete = () => {
      emit('handleDelete')
      closeDialog()
    }

    return { deleteDialog, closeDialog, handleDelete }
  }
}
</script>

<style></style>
