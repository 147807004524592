import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import pt from 'vuetify/lib/locale/pt'

Vue.use(Vuetify)

const options = {
  theme: {
    themes: {
      light: {
        default: '#7986CB'
      }
    },
    lang: {
      locales: { pt },
      current: 'pt'
    }
  }
}

export default new Vuetify(options)
