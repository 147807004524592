<template>
  <v-list-group :value="value" :prepend-icon="icon ? `mdi-${icon}` : ''">
    <template v-slot:activator>
      <v-list-item-title v-text="title ? title : ''" />
    </template>
    <slot />
  </v-list-group>
</template>

<script>
export default {
  name: 'NavListGroup',

  props: {
    icon: {
      type: [String, Boolean],
      default: false
    },
    title: {
      type: [String, Boolean],
      default: false
    },
    value: {
      type: Boolean,
      default: false
    }
  }
}
</script>
