import { fbAuth } from '@/plugins/firebase'
import store from '@/store'
export default (to, from, next) => {
  let user = fbAuth.currentUser

  if (to.name !== 'Welcome' && !user) {
    next({ name: 'Welcome' })
  } else if (to.name === 'Welcome' && user) {
    next({ name: 'Home' })
  } else {
    next()
  }

  if (to.meta.navBack) {
    store.dispatch('home/setNavBack', true)
    next()
  } else {
    store.dispatch('home/setNavBack', false)
    next()
  }
}
