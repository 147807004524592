export default [
  {
    path: '/welcome/:mode?',
    name: 'Welcome',
    component: () =>
      import(/* webpackChunkName: "welcome" */ './views/Welcome'),
    props: (route) => ({ mode: route.params.mode || 'login' }),
    meta: {
      title: 'Welcome',
      hideNav: true
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () =>
      import(/* webpackChunkName: "profile" */ './views/Profile.vue'),
    meta: {
      title: 'Perfil',
      inNavMenu: true,
      icon: 'account'
    }
  }
]
