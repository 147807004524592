<template>
  <v-img v-bind="$attrs" v-on="$listeners"></v-img>
</template>

<script>
export default {
  name: 'AppImage'
}
</script>

<style></style>
