<template>
  <v-snackbar v-bind="$attrs">
    <slot />
  </v-snackbar>
</template>

<script>
export default {
  name: 'AppSnackbar'
}
</script>

<style></style>
