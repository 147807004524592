<template>
  <app-text-field
    :icon="icon"
    v-bind="[emailFieldDefaults, $attrs]"
    type="email"
    v-on="$listeners"
    @input="(v) => $emit('input', v)"
  >
  </app-text-field>
</template>

<script>
import { computed } from '@vue/composition-api'
export default {
  name: 'TextFieldEmail',

  props: {
    icon: {
      type: String,
      default: 'email'
    }
  },

  setup() {
    const emailFieldDefaults = computed(() => ({
      placeholder: 'E-mail'
    }))

    return { emailFieldDefaults }
  }
}
</script>

<style></style>
