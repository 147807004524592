<template>
  <v-row no-gutters>
    <v-col cols="12" md="6">
      <basic-card>
        <template #title>Buttons</template>
        <v-row
          no-gutters
          class="d-flex align-content-center justify-space-around mx-2"
        >
          <app-btn
            v-for="btn in data"
            :key="`btn_${btn.color}`"
            class="mb-2 mx-1"
            :color="btn.color"
            @click="sayInfo(btn.color)"
            >{{ btn.color }}
          </app-btn>
        </v-row>
      </basic-card>
    </v-col>
    <v-col>
      <basic-card>
        <template #title>Left Icon Button</template>
        <v-row
          no-gutters
          class="d-flex align-content-center justify-space-around mx-2"
        >
          <app-btn
            v-for="btn in data"
            :key="`btn_lIcon${btn.color}`"
            class="mb-2 mx-1"
            :lIcon="btn.icon"
            :color="btn.color"
            @click="sayInfo(btn.color)"
            >{{ btn.color }}
          </app-btn>
        </v-row>
      </basic-card>
    </v-col>
    <v-col cols="12" md="6">
      <basic-card>
        <template #title>Right Icon Button</template>
        <v-row
          no-gutters
          class="d-flex align-content-center justify-space-around mx-2"
        >
          <app-btn
            v-for="btn in data"
            :key="`btn_rIcon${btn.color}`"
            class="mb-2 mx-1"
            :color="btn.color"
            :rIcon="btn.icon"
            @click="sayInfo(btn.color)"
            >{{ btn.color }}
          </app-btn>
        </v-row>
      </basic-card>
    </v-col>
    <v-col>
      <basic-card>
        <template #title>Outlined Button</template>
        <v-row
          no-gutters
          class="d-flex align-content-center justify-space-around mx-2"
        >
          <app-btn
            v-for="btn in data"
            :key="`btn_dark${btn.color}`"
            class="mb-2 mx-1"
            outlined
            :color="btn.color"
            @click="sayInfo(btn.color)"
            >{{ btn.color }}
          </app-btn>
        </v-row>
      </basic-card>
    </v-col>
    <v-col cols="12" md="6">
      <basic-card>
        <template #title>Badge Buttons</template>
        <v-row
          no-gutters
          class="d-flex align-content-center justify-space-around mx-2 px-1"
        >
          <app-badge
            v-for="btn in data"
            :key="`btn_dark${btn.color}`"
            class="mb-2 mx-1"
            :value="$utils.randomInt(1, 20)"
            :options="$utils.randomBoolean() ? 'dot' : ''"
            :color="data[$utils.randomInt(0, 7)].color"
          >
            <app-btn dark :color="btn.color" @click="sayInfo(btn.color)"
              >{{ btn.color }}
            </app-btn>
          </app-badge>
        </v-row>
      </basic-card>
    </v-col>
    <v-col>
      <basic-card>
        <template #title>Disabled Buttons</template>
        <v-row
          no-gutters
          class="d-flex align-content-center justify-space-around mx-2"
        >
          <app-btn
            v-for="btn in data"
            :key="`btn_disabled${btn.color}`"
            class="mb-2 mx-1"
            disabled
            :color="btn.color"
            @click="sayInfo(btn.color)"
            >{{ btn.color }}
          </app-btn>
        </v-row>
      </basic-card>
    </v-col>
    <v-col cols="12" md="6">
      <basic-card>
        <template #title>Loading Buttons</template>
        <v-row
          no-gutters
          class="d-flex align-content-center justify-space-around mx-2"
        >
          <app-btn
            v-for="btn in data"
            :key="`btn_block${btn.color}`"
            class="mb-2 mx-1"
            :color="btn.color"
            loading
            >{{ btn.color }}
          </app-btn>
        </v-row>
      </basic-card>
    </v-col>
    <v-col>
      <basic-card>
        <template #title>Block Buttons</template>
        <v-row
          no-gutters
          class="d-flex align-content-center justify-space-around mx-2"
        >
          <app-btn
            v-for="btn in data"
            :key="`btn_block${btn.color}`"
            class="mb-2 mx-1"
            block
            :color="btn.color"
            @click="sayInfo(btn.color)"
            >{{ btn.color }}
          </app-btn>
        </v-row>
      </basic-card>
    </v-col>
    <v-col cols="12" md="6">
      <basic-card>
        <template #title>Type</template>
        <template #subtitle>Type can be "buttom" or "submit"</template>
        <app-form @submit.prevent="handleSubmit">
          <app-text-field v-model="form"></app-text-field>
          <div class="d-flex justify-space-around">
            <app-btn type="submit">Submit</app-btn>
            <app-btn @click="sayInfo(`No Submited: ${form}`)">Buttom</app-btn>
          </div>
        </app-form>
      </basic-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import { BasicCard } from '@/components/layout/cards'
export default {
  name: 'Home',

  components: {
    BasicCard
  },

  data: () => ({
    form: ''
  }),

  computed: {
    ...mapState({
      data: (state) => state.examples.data
    })
  },

  methods: {
    sayInfo(info) {
      console.log(info.toUpperCase())
    },
    handleSubmit() {
      console.log('SUBMITED:', this.form)
    }
  }
}
</script>
