import { ref } from '@vue/composition-api'

const useDialogs = () => {
  const dialog = ref(false)

  const handleOpen = () => {
    dialog.value = true
  }

  const handleClose = () => {
    dialog.value = false
  }

  const toggleDialog = () => {
    dialog.value = !dialog.value
  }

  return { dialog, handleClose, handleOpen, toggleDialog }
}

export default useDialogs
