<template>
  <div class="py-3">
    <app-file-input
      prepend-inner-icon="mdi-image-plus"
      show-size
      accept="image/*"
      small-chips
      truncate-length="25"
      @change="handleChange"
    ></app-file-input>

    <app-snackbar :value="fileError" :timeout="1000">
      {{ fileError }}
    </app-snackbar>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
export default {
  name: 'ImageInput',

  setup(props, { emit }) {
    const fileError = ref(null)
    const file = ref(null)
    const types = ['image/png', 'image/jpeg']
    const handleChange = (e) => {
      const selected = e
      if (selected && types.includes(selected.type)) {
        if (selected.size > 5000000) {
          fileError.value = 'Imagem muito grande'
          file.value = null
        } else {
          file.value = selected
          fileError.value = null
        }
      } else {
        file.value = null
        fileError.value = 'Selecione uma imagem válida'
      }
      emit('selectedFile', file)
    }

    return { fileError, handleChange }
  }
}
</script>

<style></style>
