<template>
  <v-badge overlap :value="value" :content="content" v-bind="$attrs">
    <slot />
  </v-badge>
</template>

<script>
import { computed } from '@vue/composition-api'
export default {
  name: 'AppBadge',

  props: {
    value: {
      type: [String, Number, Boolean],
      default: false
    },
    maxValue: {
      type: Number,
      default: 9
    }
  },

  setup(props) {
    const content = computed(() => {
      return props.value > props.maxValue ? `${props.maxValue}+` : props.value
    })

    return { content }
  }
}
</script>

<style></style>
