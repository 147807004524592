import Buttons from './views/Buttons.vue'
import Colors from './views/Colors.vue'
import Typography from './views/Typography.vue'
import Fab from './views/Fab.vue'
import Icons from './views/Icons.vue'
import Badges from './views/Badges.vue'
import TextFields from './views/TextFields.vue'
import Dialogs from './views/Dialogs.vue'
import Alerts from './views/Alerts.vue'
import Tables from './views/Tables.vue'
import Loading from './views/Loading.vue'

export default [
  {
    path: '/colors',
    name: 'Colors',
    component: Colors,
    meta: {
      title: 'Colors',
      inNavExample: true,
      navBack: true,
      icon: 'palette'
    }
  },
  {
    path: '/typography',
    name: 'Typography',
    component: Typography,
    meta: {
      title: 'Typography',
      inNavExample: true,
      icon: 'format-font'
    }
  },
  {
    path: '/buttons',
    name: 'Buttons',
    component: Buttons,
    meta: {
      title: 'Botões',
      navBack: true,
      inNavExample: true,
      icon: 'account-box-multiple'
    }
  },
  {
    path: '/fab',
    name: 'Fab',
    component: Fab,
    meta: {
      title: 'Fab',
      inNavExample: true,
      icon: 'alpha-f-circle-outline'
    }
  },
  {
    path: '/icons',
    name: 'Icons',
    component: Icons,
    meta: {
      title: 'Ícones',
      inNavExample: true,
      icon: 'alpha-i-circle-outline'
    }
  },
  {
    path: '/badges',
    name: 'Badges',
    component: Badges,
    meta: {
      title: 'Badges',
      inNavExample: true,
      icon: 'alpha-b-circle-outline'
    }
  },
  {
    path: '/textfields',
    name: 'TextFields',
    component: TextFields,
    meta: {
      title: 'Text Field',
      inNavExample: true,
      icon: 'text-short'
    }
  },
  {
    path: '/dialogs',
    name: 'Dialogs',
    component: Dialogs,
    meta: {
      title: 'Dialogs',
      inNavExample: true,
      icon: 'dock-window'
    }
  },
  {
    path: '/alerts',
    name: 'Alerts',
    component: Alerts,
    meta: {
      title: 'Alerts',
      inNavExample: true,
      icon: 'alert'
    }
  },
  {
    path: '/tables',
    name: 'Tables',
    component: Tables,
    meta: {
      title: 'Tabelas',
      inNavExample: true,
      icon: 'table'
    }
  },
  {
    path: '/loading',
    name: 'Loading',
    component: Loading,
    meta: {
      title: 'Loading',
      inNavExample: true,
      icon: 'reload'
    }
  }
]
