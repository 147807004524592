<template>
  <div>
    <h1>TextFields</h1>
    <basic-card>
      <template #title>Default</template>
      <app-form @submit.prevent="handleSubmit(defaultTextField)">
        <app-text-field v-model="defaultTextField">
          <template #label> </template>
        </app-text-field>
        <app-btn type="submit">Vai</app-btn>
      </app-form>
    </basic-card>
    <basic-card>
      <template #title>Password</template>
      <app-form @submit.prevent="handleSubmit(defaultTextField)">
        <text-field-password v-model="defaultTextField"></text-field-password>
        <app-btn type="submit">Vai</app-btn>
      </app-form>
    </basic-card>
    <basic-card>
      <template #title>Email Filled</template>
      <app-form @submit.prevent="handleSubmit(defaultTextField)">
        <text-field-email
          v-model="defaultTextField"
          outlined
          :solo="false"
        ></text-field-email>
        <app-btn type="submit">Vai</app-btn>
      </app-form>
    </basic-card>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { TextFieldPassword, TextFieldEmail } from '@/components/layout/forms'
import { BasicCard } from '@/components/layout/cards'
export default {
  name: 'Textfields',

  components: {
    TextFieldPassword,
    TextFieldEmail,
    BasicCard
  },

  setup() {
    const showPassword = ref(false)
    const defaultTextField = ref('')
    const handleSubmit = (value) => {
      console.log(value)
    }
    const test = () => {
      console.log(showPassword.value)
      showPassword.value = !showPassword.value
    }

    return { defaultTextField, handleSubmit, test, showPassword }
  }
}
</script>
