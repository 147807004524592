import router from '@/router/'
import { v4 as uuid4 } from 'uuid'
import vuetify from '@/plugins/vuetify.js'
import {
  formatDistanceToNow,
  format,
  formatDistanceToNowStrict
} from 'date-fns'
import { ptBR } from 'date-fns/locale'

const routes = router.options.routes
class Utils {
  useRoute() {
    return router.app._route
  }
  uuid4() {
    return uuid4()
  }

  fileRenameToUUID4(file) {
    return `${this.uuid4()}.${file.name.split('.').pop()}`
  }
  fileRenameToTimestamp(file) {
    return `${Date.now().toString()}.${file.name.split('.').pop()}`
  }

  chatTime(timestamp) {
    return format(timestamp.toDate(), "dd/MM/yyyy 'às' H':'mm")
  }

  distanceDate(timestamp) {
    return formatDistanceToNow(timestamp.toDate(), {
      locale: ptBR
    })
  }

  distanceDateStrict(timestamp) {
    return formatDistanceToNowStrict(timestamp.toDate(), {
      locale: ptBR,
      addSuffix: true
    })
  }

  breakpointSize(width = {}) {
    let val = ''
    const xs = width.xs || '90vw'
    const sm = width.sm || 400
    const md = width.md || 400
    const lg = width.lg || 400
    const xl = width.xl || 400
    switch (vuetify.framework.breakpoint.name) {
      case 'xs':
        val = xs
        break
      case 'sm':
        val = sm
        break
      case 'md':
        val = md
        break
      case 'lg':
        val = lg
        break
      case 'xl':
        val = xl
    }
    return val
  }

  // está dando um erro de vez em quando
  delay(amount = 2000) {
    return new Promise((resolve) => setTimeout(resolve, amount))
  }

  filteredRoutes(filter) {
    const filteredRoutes = routes.filter(
      (item) => item.meta && item.meta[filter] === true
    )
    const data = filteredRoutes.map((item) => {
      return {
        id: this.uuid4(),
        title: item.meta.title,
        name: item.name,
        path: item.path,
        icon: item.meta.icon
      }
    })
    return data
  }
  randomInt(min, max) {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min)) + min
  }

  randomBoolean() {
    return this.randomInt(1, 10) % 2 === 0
  }

  getIndex(data, id) {
    const index = data.findIndex((item) => item.id === id)
    return index
  }

  getOptions(options, key) {
    const opt = options
      .split(key)
      .map((item) => {
        return {
          type: item
        }
      })
      .reduce((obj, item) => {
        return {
          ...obj,
          [item['type']]: true
        }
      }, {})

    return opt
  }
}
export default new Utils()
