<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <basic-card>
          <template #title>Cores</template>
          <template #subtitle>Cores padrão</template>
          <v-data-table
            :headers="headers"
            :items="data"
            disable-filtering
            disable-sort
            hide-default-footer
            mobile-breakpoint="350"
          >
            <template v-slot:item.preview="{ item }">
              <v-avatar :color="item.color" size="24"> </v-avatar>
            </template>
            <template v-slot:item.usage="{ item }">
              <code v-text="`<app-btn color='${item.color}'>`"> </code>
            </template>
            <template v-slot:item.hex="{ item }">
              <code> {{ item.hex }} </code>
            </template>
          </v-data-table>
        </basic-card>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" md="6">
        <basic-card>
          <template #title>Botões</template>
          <template #subtitle>Tipos de botões com a paleta de cores</template>
          <v-row align="center" justify="center" no-gutters>
            <v-checkbox
              v-for="(value, key) in baseBtnCheckbox"
              :key="`checkbox_${value.name}`"
              v-model="baseBtnCheckbox[key].value"
              class="mb-2 mx-1"
              dense
              hide-details
              :label="value.name"
            ></v-checkbox>
          </v-row>
          <v-row align="center" justify="space-around" no-gutters>
            <app-btn
              v-for="btn in data"
              :key="`btn_${btn.color}`"
              class="mb-1 mx-3"
              minWidth="148"
              :lIcon="baseBtnCheckbox.lIcon.value ? btn.icon : false"
              :rIcon="baseBtnCheckbox.rIcon.value ? btn.icon : false"
              :disabled="baseBtnCheckbox.disabled.value"
              :loading="baseBtnCheckbox.loading.value"
              :block="baseBtnCheckbox.block.value"
              :text="baseBtnCheckbox.text.value"
              :outlined="baseBtnCheckbox.outlined.value"
              :small="baseBtnCheckbox.small.value"
              :tile="baseBtnCheckbox.tile.value"
              :color="btn.color"
              @click="sayInfo(btn.color)"
              >{{ btn.color }}
            </app-btn>
          </v-row>
        </basic-card>
      </v-col>
      <v-col>
        <basic-card>
          <template #title>Ícones</template>
          <template #subtitle>Exemplos de ícones</template>
          <v-row no-gutters align="center" justify="space-around">
            <app-icon
              v-for="icon in data"
              :key="icon.icon"
              :color="icon.color"
              :icon="icon.icon"
            />
          </v-row>
        </basic-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { BasicCard } from '@/components/layout/cards'
export default {
  name: 'Colors',

  components: {
    BasicCard
  },

  data: () => ({
    baseBtnCheckbox: {
      rIcon: { name: 'Ícone Direita', value: false },
      lIcon: { name: 'Ícone Esquerda', value: false },
      disabled: { name: 'Disabled', value: false },
      loading: { name: 'Loading', value: false },
      block: { name: 'Block', value: false },
      text: { name: 'Text', value: false },
      outlined: { name: 'Outlined', value: false },
      tile: { name: 'Tile', value: false },
      small: { name: 'Small', value: false }
    },
    headers: [
      {
        text: 'Nome',
        align: 'center',
        sortable: false,
        value: 'color'
      },
      { text: 'Preview', value: 'preview' },
      { text: 'Exemplo de uso', value: 'usage' },
      { text: 'Hexadecimal', value: 'hex' }
    ]
  }),

  computed: {
    ...mapState({
      data: (state) => state.examples.data
    })
  },

  methods: {
    sayInfo(info) {
      console.log(info.toUpperCase())
    }
  }
}
</script>

<style></style>
