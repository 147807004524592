import * as types from './mutation_types'

export default {
  [types.TOGGLE_DRAWER](state) {
    state.drawer = !state.drawer
  },
  [types.CLOSE_DRAWER](state) {
    state.drawer = false
  },
  [types.SET_NAV_BACK](state, payload) {
    state.navBack = payload
  },
  [types.SET_NAV_BAR_LOADING](state, payload) {
    state.navLoading = payload
  },
  [types.SET_OVERLAY_LOADING](state, payload) {
    state.overlayLoading = payload
  },
  [types.TOGGLE_NAV_BAR_SEARCH](state) {
    state.navSearch = !state.navSearch
  }
}
