//import utils from '@/utils/utils'
const photoGenerator = () => {
  let element = []
  for (let i = 1; i < 10; i++) {
    element.push({
      id: Math.random(),
      filePath: `https://picsum.photos/100/150?random=${i}`
    })
  }

  return element
}

export default {
  albums: [
    {
      id: '0',
      title: 'Bla bla bla',
      userId: '"STqdvA8DAPS6ZMsZv8wRej6H9Zy2"',
      photos: photoGenerator()
    },
    {
      id: '1',
      title: 'Bla bla bla',
      userId: '"STqdvA8DAPS6ZMsZv8wRej6H9Zy2"',
      photos: photoGenerator()
    },
    {
      id: '2',
      title: 'Bla bla bla',
      userId: '"STqdvA8DAPS6ZMsZv8wRej6H9Zy2"',
      photos: photoGenerator()
    }
  ]
}
