import './installCompositionApi'
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { fbAuth } from '@/plugins/firebase.js'
import '@babel/polyfill'
// Vue.use(FirebaseVue)

//import VueCompositionAPI from '@vue/composition-api'
// Vue.use(VueCompositionAPI)

import Utils from './utils/utils'
Vue.prototype.$utils = Utils

import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

const requireComponent = require.context(
  './components/app',
  false,
  /App[A-Z]\w+\.(vue|js)$/
)

requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName)
  const componentName = upperFirst(
    camelCase(
      fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, '')
    )
  )

  Vue.component(componentName, componentConfig.default || componentConfig)
})

Vue.config.productionTip = false

// ??????????????????????????
fbAuth.onAuthStateChanged(() => {
  new Vue({
    name: 'Main',
    router,
    store,
    vuetify,

    render: (h) => h(App)
  }).$mount('#app')
})
