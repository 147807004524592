<template>
  <v-navigation-drawer v-bind="$attrs">
    <slot />
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'NavDrawer'
}
</script>

<style></style>
