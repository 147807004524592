<template>
  <div v-if="loggedUser" :class="!drawer ? 'd-flex' : 'text-center'">
    <v-badge color="null" :value="showEdit" bottom offset-x="15" offset-y="15">
      <template v-slot:badge>
        <app-icon
          color="#FFF"
          size="24"
          icon="pencil"
          @click="openChangePhotoURLSheet"
        />
      </template>

      <v-avatar class="ma-0" :size="avatarSize">
        <img
          :src="
            loggedUser.photoURL ||
            'https://firebasestorage.googleapis.com/v0/b/components-80659.appspot.com/o/default%2Fdefault-profile-icon-16.jpg?alt=media&token=c070e80c-8e6c-44c1-ad79-6263efb1f6dd'
          "
          alt="Foto do Perfil"
        />
      </v-avatar>
    </v-badge>

    <div class="d-flex flex-column justify-center">
      <div v-if="showName" class="text-h6">
        {{ loggedUser.displayName ? loggedUser.displayName : loggedUser.email }}
      </div>
      <div
        v-if="$vuetify.breakpoint.lgAndUp || showUsername"
        class="text-subtitle-1"
      >
        {{ loggedUser.email }}
      </div>
    </div>

    <bottom-sheet
      :dialog="changePhotoURLSheet"
      @close="closeChangePhotoURLSheet"
    >
      <app-form @submit.prevent="changePhotoURL">
        <image-input @selectedFile="selectedFile" />
        <app-btn type="submit" lIcon="check" color="primary" block
          >Salvar</app-btn
        >
      </app-form>
    </bottom-sheet>
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import { fbAuth } from '@/plugins/firebase'
import { ImageInput } from '@/components/layout/forms'
import { BottomSheet, useDialogs } from '@/components/layout/dialogs'
import { useStorage, useDocument } from '@/composables'
export default {
  name: 'Profile',

  components: {
    ImageInput,
    BottomSheet
  },

  props: {
    showEdit: {
      type: Boolean,
      default: false
    },
    showUsername: {
      type: Boolean,
      default: false
    },
    showName: {
      type: Boolean,
      default: false
    },
    avatarSize: {
      type: [String, Number],
      default: 32
    },
    drawer: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const file = ref(null)
    const fileError = ref(false)
    const formError = ref(true)
    const loggedUser = computed(() => store.state.auth.loggedUser)
    const { filePath, url, uploadImage } = useStorage()
    const {
      dialog: changePhotoURLSheet,
      handleOpen: openChangePhotoURLSheet,
      handleClose: closeChangePhotoURLSheet
    } = useDialogs()
    /* const changePhotoURL = async () => {
      console.log('botao funciona: ', file)
    } */

    const selectedFile = (item) => {
      console.log('Este é o arquivo', item.value)
      file.value = item.value
    }

    const changePhotoURL = async () => {
      if (file.value) {
        const user = fbAuth.currentUser
        store.dispatch('home/setOverlayLoading', true)
        await uploadImage(
          file.value,
          loggedUser.value,
          'avatar',
          loggedUser.value.uid
        )
        await user.updateProfile({
          photoURL: url.value
        })
        const { updateDoc } = useDocument('users', loggedUser.value.uid)
        await updateDoc({
          coverURLPath: filePath.value
        })

        store.dispatch('home/setOverlayLoading', false)
        console.log(filePath)
        closeChangePhotoURLSheet()
        fileError.value = 'Imagem atualizada com sucesso'
        router.go()
      } else {
        fileError.value = 'Imagem inválida'

        file.value = null
      }
    }

    return {
      changePhotoURLSheet,
      file,
      fileError,
      formError,
      loggedUser,
      changePhotoURL,
      selectedFile,
      openChangePhotoURLSheet,
      closeChangePhotoURLSheet
    }
  }
}
</script>

<style></style>
