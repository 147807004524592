import { fbStorage } from '@/plugins/firebase'
import { ref } from '@vue/composition-api'
import utils from '@/utils/utils'

const useStorage = () => {
  const error = ref(null)
  const url = ref(null)
  const filePath = ref(null)

  const uploadImage = async (file, user, folder, filename = null) => {
    const newFileName = filename ? filename : utils.fileRenameToUUID4(file)
    filePath.value = `${folder}/${user.uid}/${newFileName}`
    console.log(filePath.value)
    const storageRef = fbStorage.ref(filePath.value)
    try {
      const res = await storageRef.put(file)
      url.value = await res.ref.getDownloadURL()
    } catch (err) {
      console.log(err.message)
      error.value = err.message
    }
  }

  const deleteImage = async (path) => {
    const storageRef = fbStorage.ref(path)
    try {
      await storageRef.delete()
    } catch (err) {
      console.log(err.message)
      error.value = err.message
    }
  }

  return { error, url, filePath, uploadImage, deleteImage }
}

export default useStorage
