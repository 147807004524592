<template>
  <v-alert
    v-bind="$attrs"
    transition="fade-transition"
    border="left"
    :value="$attrs.value"
    v-on="$listeners"
  >
    <template #prepend>
      <app-icon
        :color="!$attrs.outlined ? '#FFF' : $attrs.type"
        size="56"
        :icon="icon"
      />
    </template>
    <slot />
  </v-alert>
</template>

<script>
export default {
  name: 'AppAlert',

  computed: {
    icon() {
      let icon = ''
      switch (this.$attrs.type) {
        case 'success':
          icon = 'check-circle-outline'
          break
        case 'error':
          icon = 'close-circle-outline'
          break
        case 'warning':
          icon = 'alert-circle-outline'
          break
        default:
          icon = 'information-outline'
      }
      return icon
    }
  }
}
</script>

<style></style>
