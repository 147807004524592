export const doneTasks = (state) => {
  const done = state.tasks.filter((item) => item.isDone === true)
  return done
}

export const undoneTasks = (state) => {
  const undone = state.tasks.filter((item) => item.isDone === false)
  return undone
}

export const doneTasksPercent = (state, getters) => {
  const doneTasksPercent = Math.trunc(
    (getters.doneTasks.length / state.tasks.length) * 100
  )
  return doneTasksPercent
}
