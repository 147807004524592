import { routes as auth } from '@/modules/core/auth/'
import { routes as home } from '@/modules/core/home/'

import { routes as tasks } from '@/modules/widgets/tasks/'
import { routes as messages } from '@/modules/widgets/messages/'
import { routes as blog } from '@/modules/widgets/blog/'
import { routes as chat } from '@/modules/widgets/chat/'
import { routes as playlists } from '@/modules/widgets/playlists/'
import { routes as calc } from '@/modules/widgets/calc/'
import { routes as albums } from '@/modules/widgets/albums/'

import { routes as examples } from '@/modules/widgets/examples/'
export default [
  ...auth,
  ...home,
  ...tasks,
  ...messages,
  ...chat,
  ...blog,
  ...examples,
  ...playlists,
  ...calc,
  ...albums
]
