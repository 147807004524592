<template>
  <app-card class="ma-1" width="150" @click="() => $emit('handleClick')">
    <v-sheet width="160" max-width="160" max-height="160">
      <app-img
        :aspect-ratio="16 / 9"
        width="152"
        max-width="152"
        :src="coverUrl"
        :lazy-src="`https://picsum.photos/1/2?random=5`"
      />
      <div class="text-body-2 text-center">{{ title }}</div>
    </v-sheet>
  </app-card>
</template>

<script>
export default {
  name: 'ImageThumb',

  props: {
    coverUrl: {
      type: String,
      default:
        'https://firebasestorage.googleapis.com/v0/b/components-80659.appspot.com/o/default%2Fdefault-album-photo-cover.PNG?alt=media&token=a94de3c3-c303-49af-ba73-e0c7e9144e09'
    },
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style></style>
