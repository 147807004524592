<template>
  <app-btn fab :ripple="false" fixed v-bind="[pos, $attrs]" v-on="$listeners">
    <app-icon :icon="icon"></app-icon>
  </app-btn>
</template>

<script>
import { ref } from '@vue/composition-api'
import useAppComponents from './useAppComponets'

export default {
  name: 'AppBtnFab',

  props: {
    icon: {
      type: String,
      default: ''
    },
    position: {
      type: String,
      default: 'right-bottom'
    }
  },

  setup(props) {
    const { fabPosition } = useAppComponents()
    const pos = ref(fabPosition(props.position))
    return { pos }
  }
}
</script>

<style></style>
