<template>
  <div>
    <v-card>
      <v-card-title>Tipografia</v-card-title>
      <v-card-text class="pa-3">
        <div
          v-for="item in typography"
          :key="item.tag"
          :class="`text-${item.tag}`"
          v-text="item.text"
        ></div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Typography',

  computed: {
    ...mapState({
      typography: (state) => state.examples.typography
    })
  }
}
</script>

<style></style>
