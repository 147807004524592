import { ref } from '@vue/composition-api'

const useAppComponents = () => {
  const fabPosition = (data) => {
    const position = ref({})
    const validPosition = [
      'right-top',
      'right-bottom',
      'left-bottom',
      'left-top'
    ]
    if (!validPosition.includes(data)) {
      console.error(
        `Validation Error: '${data}' is a invalid position. Position is defined to 'right-bottom'`
      )
      position.value.right = true
      position.value.bottom = true
      return position
    }
    if (data.includes('right')) {
      position.value.right = true
      position.value.left = false
    } else {
      position.value.left = true
      position.value.right = false
    }
    if (data.includes('bottom')) {
      position.value.bottom = true
      position.value.top = false
    } else {
      position.value.top = true
      position.value.bottom = false
    }
    return position
  }

  return { fabPosition }
}

export default useAppComponents
