import { ref, watchEffect } from '@vue/composition-api'
import { fbFirestore } from '@/plugins/firebase.js'

const getDocument = (collection, id) => {
  const document = ref(null)
  const error = ref(null)

  let documentRef = fbFirestore.collection(collection).doc(id)
  const unsub = documentRef.onSnapshot(
    // documentRef.onSnapshot(
    (doc) => {
      if (doc.data()) {
        document.value = { ...doc.data(), id: doc.id }
        error.value = null
      } else {
        error.value = 'Não encontrado'
      }
    },
    (err) => {
      console.log(err.message)
      document.value = null
      error.value = 'Não encontrado'
    }
  )

  watchEffect((onInvalidate) => {
    onInvalidate(() => unsub())
  })

  return { error, document }
}

export default getDocument
