<template>
  <v-row no-gutters align="center" justify="space-around">
    <v-col cols="12" md="6" lg="4">
      <basic-card>
        <template #title>Icons</template>
        <template #subtitle>Default Icons</template>
        <v-row no-gutters align="center" justify="space-around">
          <app-icon
            v-for="icon in data"
            :key="icon.icon"
            :color="icon.color"
            :icon="icon.icon"
          />
        </v-row>
      </basic-card>
    </v-col>

    <v-col cols="12" md="6" lg="4">
      <basic-card>
        <template #title>Badged Icons</template>
        <template #subtitle>Icons with a dot Badge</template>
        <v-row no-gutters align="center" justify="space-around">
          <app-badge
            v-for="icon in data"
            :key="icon.icon"
            :value="$utils.randomInt(1, 20)"
            :options="$utils.randomBoolean() ? 'dot' : ''"
            :color="data[$utils.randomInt(0, 7)].color"
          >
            <app-icon :color="icon.color" :icon="icon.icon" />
          </app-badge>
        </v-row>
      </basic-card>
    </v-col>

    <v-col cols="12" md="6" lg="4">
      <basic-card>
        <template #title>Button Icons</template>
        <template #subtitle>Icons with button action</template>
        <v-row no-gutters align="center" justify="space-around">
          <app-btn v-for="icon in data" :key="icon.icon" icon>
            <app-icon :color="icon.color" :icon="icon.icon"></app-icon>
          </app-btn>
        </v-row>
      </basic-card>
    </v-col>

    <v-col cols="12" md="6" lg="4">
      <basic-card>
        <template #title>Dense Icons</template>
        <v-row no-gutters align="center" justify="space-around">
          <app-icon
            v-for="icon in data"
            :key="icon.icon"
            options="dense"
            :color="icon.color"
            :icon="icon.icon"
          />
        </v-row>
      </basic-card>
    </v-col>
    <v-col cols="12" md="6" lg="4">
      <basic-card>
        <template #title>Disabled Icons</template>
        <v-row no-gutters align="center" justify="space-around">
          <app-icon
            v-for="icon in data"
            :key="icon.icon"
            disabled
            :color="icon.color"
            :icon="icon.icon"
          />
        </v-row>
      </basic-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import { BasicCard } from '@/components/layout/cards'

export default {
  name: 'Home',

  components: {
    BasicCard
  },

  computed: {
    ...mapState({
      data: (state) => state.examples.data
    })
  },

  methods: {
    sayInfo(info) {
      console.log(info.toUpperCase())
    }
  }
}
</script>
