export default [
  {
    path: '/albums',
    name: 'Albums',
    component: () =>
      import(/* webpackChunkName: "albums" */ './views/Albums.vue'),
    meta: {
      title: 'Album de Fotos',
      inNavMenu: true,
      icon: 'image-multiple'
    }
  },
  {
    path: '/albums/:id',
    name: 'AlbumDetails',
    component: () =>
      import(/* webpackChunkName: "albums" */ './views/AlbumDetails.vue'),
    props: true
  }
]
