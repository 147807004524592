export default {
  data: [
    { color: 'primary', hex: '#1976D2', notification: 4, icon: 'download' },
    { color: 'secondary', hex: '#424242', notification: 2, icon: 'menu' },
    { color: 'accent', hex: '#82B1FF', notification: 1, icon: 'magnify' },
    {
      color: 'error',
      hex: '#FF5252',
      notification: 101,
      icon: 'account-outline'
    },
    {
      color: 'info',
      hex: '#2196F3',
      notification: 6,
      icon: 'data-matrix-minus'
    },
    {
      color: 'success',
      hex: '#4CAF50',
      notification: 10,
      icon: 'folder-account'
    },
    { color: 'warning', hex: '#FFC107', notification: 3, icon: 'gondola' },
    { color: 'default', hex: '#7986CB', notification: 9, icon: 'ubisoft' }
  ],
  typography: [
    { text: 'Heading 1', tag: 'h1' },
    { text: 'Heading 2', tag: 'h2' },
    { text: 'Heading 3', tag: 'h3' },
    { text: 'Heading 4', tag: 'h4' },
    { text: 'Heading 5', tag: 'h5' },
    { text: 'Heading 6', tag: 'h6' },
    { text: 'Subtitle 1', tag: 'subtitle-1' },
    { text: 'Subtitle 2', tag: 'subtitle-2' },
    { text: 'Body 1', tag: 'body-1' },
    { text: 'Body 2', tag: 'body-2' },
    { text: 'Button', tag: 'button' },
    { text: 'Caption', tag: 'caption' },
    { text: 'Overline', tag: 'overline' }
  ]
}
