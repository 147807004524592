import * as types from './mutation_types'

export const toggleDrawer = ({ commit }) => {
  commit(types.TOGGLE_DRAWER)
}
export const closeDrawer = ({ commit }) => {
  commit(types.CLOSE_DRAWER)
}
export const setNavBack = ({ commit }, payload) => {
  commit(types.SET_NAV_BACK, payload)
}
export const setNavLoading = ({ commit }, payload) => {
  commit(types.SET_NAV_BAR_LOADING, payload)
}
export const toggleNavSearch = ({ commit }) => {
  commit(types.TOGGLE_NAV_BAR_SEARCH)
}
export const setOverlayLoading = ({ commit }, payload) => {
  commit(types.SET_OVERLAY_LOADING, payload)
}
