<template>
  <div>
    <h1>Dialogs</h1>
    <v-list three-line>
      <v-list-item v-for="(item, i) in users" :key="item.uid">
        <v-list-item-avatar>
          <v-img :src="item.coverUrl"></v-img>
        </v-list-item-avatar>

        <v-list-item-content @click="gotoDetails(item)">
          <v-list-item-title
            >{{ item.displayName || 'Sem Nome' }}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action
          ><delete-dialog
            :loading="isPending && index === i"
            icon="delete-forever"
            :itemName="item.displayName"
            @handleDelete="handleDelete(item)"
          ></delete-dialog
        ></v-list-item-action>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
// import utils from '@/utils/utils'
import { ref } from '@vue/composition-api'
import { DeleteDialog } from '@/components/layout/dialogs'
export default {
  name: 'Dialogs',

  components: {
    DeleteDialog
  },

  setup() {
    const isPending = ref(true)
    const index = ref(-1)
    const users = ref([
      {
        uid: 'ahuduahdua06969960hduahuahd',
        coverUrl: 'https://randomuser.me/api/portraits/thumb/men/75.jpg',
        displayName: 'Carlos Rodrigo'
      },
      {
        uid: 'ahud960574367uahduahduahuahd',
        coverUrl: 'https://randomuser.me/api/portraits/thumb/men/76.jpg',
        displayName: 'Carlos Macedo'
      },
      {
        uid: 'ahuduahduah907996duahuahd',
        coverUrl: 'https://randomuser.me/api/portraits/thumb/men/77.jpg',
        displayName: 'Igor Ferrari'
      },
      {
        uid: 'ahuduahd86985467uahduahuahd',
        coverUrl: 'https://randomuser.me/api/portraits/thumb/men/78.jpg',
        displayName: 'Tirso Fukeba'
      }
    ])
    const handleDelete = async (item) => {
      console.log('Vai excluir o usuário', item.displayName)
      isPending.value = true
      index.value = users.value.findIndex((user) => user.uid === item.uid)
      // await utils.delay()
      users.value = users.value.filter((user) => user.uid !== item.uid)
      isPending.value = false
      index.value = -1
    }
    const gotoDetails = (item) => {
      console.log('Vai até o usuátio', item.displayName)
    }

    return { users, isPending, index, handleDelete, gotoDetails }
  }
}
</script>

<style></style>
