import Vue from 'vue'
import * as types from './mutation_types'

export default {
  [types.TOGGLE_TASK](state, payload) {
    const i = state.tasks.findIndex((item) => item.id === payload.id)
    const isDone = !state.tasks[i].isDone
    Vue.set(state.tasks, i, { ...state.tasks[i], isDone })
  },
  [types.ADD_TASK](state, payload) {
    state.tasks.push(payload)
  }
}
