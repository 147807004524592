<template>
  <v-file-input v-bind="[fileInputDefaults, $attrs]" v-on="$listeners">
    <slot />
  </v-file-input>
</template>

<script>
import { computed } from '@vue/composition-api'
export default {
  name: 'AppFileInput',

  setup() {
    const fileInputDefaults = computed(() => ({
      solo: true,
      dense: true,
      'prepend-icon': '',
      'prepend-inner-icon': '$file'
    }))
    return { fileInputDefaults }
  }
}
</script>

<style></style>
