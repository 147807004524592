import { ref, watchEffect } from '@vue/composition-api'
import { fbFirestore } from '@/plugins/firebase.js'

const getCollection = (collection, query) => {
  const documents = ref(null)
  const error = ref(null)

  let collectionRef = fbFirestore.collection(collection).orderBy('createdAt')
  if (query) {
    collectionRef = collectionRef.where(...query)
  }

  const unsub = collectionRef.onSnapshot(
    // collectionRef.onSnapshot(
    (snap) => {
      let results = []
      snap.docs.forEach((doc) => {
        doc.data().createdAt && results.push({ ...doc.data(), id: doc.id })
      })
      documents.value = results
      error.value = null
    },
    (error) => {
      console.log(error.message)
      documents.value = null
      error.value = 'Nenhum dado a ser exibido.'
    }
  )

  watchEffect((onInvalidate) => {
    onInvalidate(() => unsub())
  })

  return { error, documents }
}

export default getCollection
