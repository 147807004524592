<template>
  <v-row no-gutters justify="space-around">
    <v-col>
      <basic-card v-for="(item, i) in props" :key="i">
        <template #title>{{ item.name }}</template>
        <app-btn
          v-for="i in data"
          :key="i.color"
          class="mx-2"
          :[item.name]="item.value"
          :color="i.color"
          fab
        >
          <app-icon :icon="i.icon"></app-icon>
        </app-btn>
      </basic-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import { BasicCard } from '@/components/layout/cards'

export default {
  name: 'Icons',

  components: {
    BasicCard
  },

  data: () => ({
    props: [
      { name: 'default' },
      { name: 'disabled', value: true },
      { name: 'loading', value: true },
      { name: 'outlined', value: true },
      { name: 'small', value: true }
    ]
  }),

  computed: {
    ...mapState({
      data: (state) => state.examples.data
    })
  },

  methods: {
    sayInfo(info) {
      console.log(info.toUpperCase())
    }
  }
}
</script>
