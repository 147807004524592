import { ref } from '@vue/composition-api'
import { fbAuth } from '@/plugins/firebase'
import store from '@/store'
const error = ref(null)

const logout = async () => {
  error.value = null
  try {
    await fbAuth.signOut()
    store.dispatch('auth/setLoggedUser', null)
    error.value = null
  } catch (err) {
    console.log(err.message)
    error.value = err.message
  }
}

const useLogout = () => {
  return { logout, error }
}

export default useLogout
