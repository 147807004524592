export default [
  {
    path: '/messages',
    name: 'Messages',
    component: () =>
      import(/* webpackChunkName: "login" */ './views/Messages.vue'),
    meta: {
      title: 'Mensagens',
      inNavMenu: true,
      icon: 'email'
    }
  }
]
