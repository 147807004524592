<template>
  <v-list-item active-class="active-route" :to="{ name }" @click="action">
    <template v-if="!reverse">
      <v-list-item-title v-text="title" />
      <v-list-item-icon>
        <app-icon :icon="icon" />
      </v-list-item-icon>
    </template>
    <template v-else>
      <v-list-item-icon>
        <app-icon :icon="icon" />
      </v-list-item-icon>
      <v-list-item-title v-text="title" />
    </template>
  </v-list-item>
</template>

<script>
export default {
  name: 'NavListitem',

  props: {
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      required: true
    },
    patch: {
      type: String,
      default: ''
    },
    reverse: {
      type: Boolean,
      default: false
    }
  },

  setup(props, { emit }) {
    const action = () => {
      emit('action')
    }

    return { action }
  }
}
</script>

<style>
.active-route {
  color: green !important;
}
</style>
