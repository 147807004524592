export default [
  {
    path: '/home',
    alias: '/',
    name: 'Home',
    component: () =>
      import(
        /* webpackChunkName: "login" */ '@/modules/core/home/views/Home.vue'
      ),
    meta: {
      title: 'Home',
      icon: 'home'
    }
  },
  {
    path: '*',
    // path: '/:catchAll(.*)', vue 3
    name: '404',
    component: () =>
      import(
        /* webpackChunkName: "login" */ '@/modules/core/home/views/NotFound.vue'
      ),
    meta: {
      title: '404',
      inNav: false
    }
  }
]
