<template>
  <v-app>
    <template v-if="!this.$route.meta.hideNav">
      <nav-bar loading :drawer="drawer" @toggleDrawer="toggleDrawer">
        <template #widgetSlot>
          <form-search class="flex-grow-1 mr-2"></form-search>
        </template>

        <template #widgetSlotIcon>
          <app-icon
            v-if="$vuetify.breakpoint.xsOnly"
            class="mr-2"
            icon="magnify"
            @click="toggleSearch"
          ></app-icon>
        </template>

        <template #rSlot2>
          <app-badge class="mr-2" dot color="error" :value="5">
            <app-icon
              icon="email"
              @click="$router.push({ name: 'Messages' })"
            ></app-icon>
          </app-badge>
        </template>

        <template #rSlot1>
          <profile-menu />
        </template>
      </nav-bar>

      <v-navigation-drawer v-model="drawer" app disable-resize-watcher>
        <v-card
          flat
          height="124"
          class="pa-1 d-flex"
          :class="!detais ? 'justify-center' : 'justify-space-around'"
        >
          <profile drawer showUsername showName avatarSize="64"></profile>
          <v-sheet v-if="detais" width="64" color="primary"></v-sheet>
        </v-card>
        <app-divider class="mt-3"></app-divider>
        <nav-drawer-items />
        <app-divider />
      </v-navigation-drawer>
    </template>
    <v-main>
      <v-container fluid class="pa-0">
        <router-view :key="$route.fullPath" />
        <overlay-loading :isPending="overlayLoading"></overlay-loading>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { computed } from '@vue/composition-api'
import { NavBar } from '@/components/layout/nav'
import OverlayLoading from '@/components/layout/loading/OverlayLoading'
//import NavDrawer from '@/components/layout/nav/NavDrawer'
import NavDrawerItems from '@/components/widget/NavDrawerItems'
import ProfileMenu from '@/components/widget/ProfileMenu'
import Profile from '@/modules/core/auth/components/Profile'
import FormSearch from '@/components/widget/FormSearch'
import getUser from '@/modules/core/auth/composables/getUser'
import store from '@/store'

export default {
  name: 'App',

  components: {
    NavBar,
    //NavDrawer,
    NavDrawerItems,
    ProfileMenu,
    Profile,
    FormSearch,
    OverlayLoading
  },

  setup() {
    const { user } = getUser()

    const navSearch = computed(() => store.state.home.navSearch)
    const overlayLoading = computed(() => store.state.home.overlayLoading)

    return { user, navSearch, overlayLoading }
  },

  data: () => ({
    detais: !true,
    drawer: !true
  }),

  watch: {
    $route() {
      window.scroll(0, 0)
    }
  },

  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer
    },
    toggleSearch() {
      store.dispatch('home/toggleNavSearch')
    }
  }
}
</script>
