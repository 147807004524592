<template>
  <v-btn
    :loading="$attrs.disabled ? false : $attrs.loading"
    :outlined="$attrs.text ? false : $attrs.outlined"
    v-bind="[btnDefaults, $attrs]"
    v-on="$listeners"
  >
    <app-icon v-if="lIcon" left :icon="lIcon"></app-icon>
    <slot />
    <app-icon v-if="rIcon" right :icon="rIcon"></app-icon>
  </v-btn>
</template>

<script>
import { computed } from '@vue/composition-api'
export default {
  name: 'AppBtn',

  props: {
    lIcon: {
      type: [String, Boolean],
      default: false
    },
    rIcon: {
      type: [String, Boolean],
      default: false
    }
  },

  setup() {
    const btnDefaults = computed(() => ({
      color: 'default'
    }))

    return { btnDefaults }
  }
}
</script>

<style></style>
