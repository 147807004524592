<template>
  <v-overlay
    z-index="1000"
    color="#000000"
    :value="isPending"
    :opacity="opacity"
  >
    <v-progress-circular
      :color="color"
      indeterminate
      size="64"
      width="5"
    ></v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  name: 'OverlayLoading',

  props: {
    opacity: {
      type: Number,
      default: 0.85
    },
    color: {
      type: String,
      default: 'default'
    },
    isPending: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style></style>
