<template>
  <v-textarea v-bind="$attrs" v-on="$listeners"><slot /></v-textarea>
</template>

<script>
export default {
  name: 'AppTextarea'
}
</script>

<style></style>
