<template>
  <app-form
    class="d-flex align-center justify-space-between"
    @submit.prevent="handleSubmit"
  >
    <transition name="wrapper" origin="bottom left 0">
      <app-text-field
        v-if="$vuetify.breakpoint.smAndUp || navSearch"
        v-model="searchData"
        icon="magnify"
        rounded
        placeholder="Faça sua busca"
        @keypress.enter.prevent="handleSubmit"
      >
        <template #append>
          <app-icon
            v-if="$vuetify.breakpoint.xsOnly"
            color="default"
            icon="close"
            @click="toggleSearch"
          ></app-icon>
        </template>
      </app-text-field>
    </transition>
  </app-form>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import store from '@/store'
export default {
  name: 'FormSearch',

  setup() {
    const searchData = ref('')

    const handleSubmit = () => {
      console.log('Vai buscar o dado?', searchData.value)
    }

    const toggleSearch = () => {
      store.dispatch('home/toggleNavSearch')
    }

    const navSearch = computed(() => store.state.home.navSearch)

    return { navSearch, searchData, handleSubmit, toggleSearch }
  }
}
</script>
<style scoped>
.wrapper-enter-active {
  animation: finished 1.5s reverse;
}

@keyframes finished {
  0% {
    opacity: 1;
    top: 0;
  }
  50% {
    opacity: 1;
    top: 0;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    top: -100vh;
  }
}
</style>
