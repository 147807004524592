export default [
  {
    path: '/playlists/create',
    name: 'CreatePlaylist',
    component: () =>
      import(
        /* webpackChunkName: "playlists-create" */ './views/CreatePlaylist.vue'
      ),
    meta: {
      title: 'Create Playlist',
      navBack: true
    }
  },
  {
    path: '/playlists',
    name: 'HomePlaylist',
    component: () =>
      import(/* webpackChunkName: "playlists" */ './views/HomePlaylist.vue'),
    meta: {
      title: 'Playlists',
      inNavWidgets: true,
      icon: 'playlist-music'
    }
  },
  {
    path: '/playlists/user',
    name: 'UserPlaylists',
    component: () =>
      import(
        /* webpackChunkName: "user-playlists" */ './views/UserPlaylists.vue'
      ),
    meta: {
      title: 'Minhas Playlists',
      inNavMenu: true,
      navBack: true,
      icon: 'playlist-play'
    }
  },
  {
    path: '/playlists/:id',
    name: 'PlaylistDetails',
    component: () =>
      import(
        /* webpackChunkName: "playlist-details" */ './views/PlaylistDetails.vue'
      ),
    meta: {
      title: 'Detalhes da Playlist',
      navBack: true
    },
    props: true
  }
]
