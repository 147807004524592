<template>
  <div>
    <h1>Alerts</h1>
    <v-row no-gutters align="center">
      <v-col v-for="item in alertTypes" :key="item.id" cols="12" sm="6" md="3">
        <basic-card>
          <template #title>{{ item.title }}</template>
          <template #subtitle>{{ item.subtitle }}</template>
          <app-alert
            :outlined="item.outlined"
            :dense="item.dense"
            :dismissible="item.dismissible"
            :elevation="item.elevation"
            :width="item.width"
            :type="item.type"
            >{{ item.text }}</app-alert
          >
        </basic-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { BasicCard } from '@/components/layout/cards'
export default {
  name: 'Alerts',

  components: {
    BasicCard
  },

  data: () => ({
    alertTypes: [
      {
        //value: true,
        id: Math.trunc(Math.random() * 1000000),
        type: 'info',
        title: 'Info',
        subtitle: 'Alert padrão, sem nenhuma opção',
        text: 'Apenas uma informação'
      },
      {
        value: true,
        id: Math.trunc(Math.random() * 1000000),
        type: 'success',
        title: 'Success',
        subtitle: 'Alert padrão, sem nenhuma opção',
        text: 'Você fez tudo certo'
      },
      {
        value: true,
        id: Math.trunc(Math.random() * 1000000),
        type: 'error',
        title: 'Error',
        subtitle: 'Alert padrão, sem nenhuma opção',
        text: 'Voce está fazendo algo errado'
      },
      {
        value: true,
        id: Math.trunc(Math.random() * 1000000),
        type: 'warning',
        title: 'Warning',
        subtitle: 'Alert padrão, sem nenhuma opção',
        text: 'Cuidado para não fazer algo errado'
      },
      {
        value: true,
        id: Math.trunc(Math.random() * 1000000),
        type: 'info',
        title: 'Outlined',
        subtitle: 'Com a opção outlined',
        text: 'Apenas uma informação',
        outlined: true
      },
      {
        value: true,
        id: Math.trunc(Math.random() * 1000000),
        type: 'success',
        title: 'Success',
        subtitle: 'Com a opção dense',
        text: 'Você fez tudo certo',
        dense: true
      },
      {
        value: true,
        id: Math.trunc(Math.random() * 1000000),
        type: 'error',
        title: 'Error',
        subtitle: 'Com as opções dismissible e dense',
        text: 'Voce está fazendo algo errado',
        dismissible: true,
        dense: true
      },
      {
        value: true,
        id: Math.trunc(Math.random() * 1000000),
        type: 'warning',
        title: 'Warning',
        subtitle: 'Com a opção dismissible e elevação de 24',
        text: 'Cuidado para não fazer algo errado',
        elevation: 24,
        dense: true,
        width: '200'
      }
    ]
  })
}
</script>

<style></style>
