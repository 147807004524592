export default [
  {
    path: '/tasks',
    name: 'Tasks',
    component: () =>
      import(/* webpackChunkName: "login" */ './views/Tasks.vue'),
    meta: {
      title: 'Tarefas',
      inNavMenu: true,
      icon: 'clock'
    }
  }
]
