<template>
  <v-text-field
    class="ma-2"
    v-bind="[textFieldDefaults, $attrs]"
    :prepend-inner-icon="icon ? `mdi-${icon}` : ''"
    v-on="$listeners"
    @input="(v) => $emit('input', v)"
  >
    <template v-for="slot in parentSlots" #[slot]>
      <slot :name="slot" />
    </template>
  </v-text-field>
</template>

<script>
import { computed } from '@vue/composition-api'
export default {
  name: 'AppTextField',

  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    icon: {
      type: [String, Boolean],
      default: null
    }
  },

  setup(props, { slots }) {
    const textFieldDefaults = computed(() => ({
      dense: true,
      solo: true,
      color: 'default',
      'hide-details': true,
      value: props.value
    }))

    const parentSlots = computed(() => Object.keys(slots))

    return { parentSlots, textFieldDefaults }
  }
}
</script>

<style></style>
