<template>
  <v-menu
    v-model="profileMenu"
    :close-on-content-click="false"
    :nudge-width="200"
    offset-x
    origin="top right"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <profile />
      </div>
    </template>

    <v-card>
      <v-card-title>
        <profile showUsername @click="openMenu" />
        <v-spacer></v-spacer>
        <app-icon icon="logout" @click="logoutClick"></app-icon>
      </v-card-title>
      <app-divider />
      <nav-list>
        <nav-list-item
          v-for="route in profileMenuRoutes"
          :key="route.id"
          :title="route.title"
          :name="route.name"
          :icon="route.icon"
          @action="closeMenu"
        />
      </nav-list>
      <app-divider />
      <v-list dense>
        <v-subheader>Configurações</v-subheader>
        <v-list-item>
          <v-list-item-action>
            <v-switch v-model="$vuetify.theme.dark" color="default">
              <template v-slot:label>
                <v-list-item-title class="ml-3">Modo Escuro</v-list-item-title>
              </template>
            </v-switch>
          </v-list-item-action>
        </v-list-item>
        <v-list-item>
          <v-list-item-action>
            <v-switch v-model="config2" color="default">
              <template v-slot:label>
                <v-list-item-title class="ml-3"
                  >Alguma Configuração</v-list-item-title
                >
              </template>
            </v-switch>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import utils from '@/utils/utils'
import router from '@/router'
import store from '@/store'
import useLogout from '@/modules/core/auth/composables/useLogout'
import Profile from '@/modules/core/auth/components/Profile'
import NavList from '@/components/layout/nav/NavList'
import NavListItem from '@/components/layout/nav/NavListItem'
export default {
  name: 'ProfileMenu',

  components: {
    Profile,
    NavList,
    NavListItem
  },

  setup() {
    const loggedUser = computed(() => store.state.auth.loggedUser)
    const profileMenu = ref(false)
    const config2 = ref(true)
    const { error, logout } = useLogout()

    const logoutClick = async () => {
      await logout()
      if (!error.value) {
        router.push({ name: 'Welcome' })
      }
    }

    const openMenu = () => {
      profileMenu.value = true
    }
    const closeMenu = async () => {
      await utils.delay(500)
      profileMenu.value = false
    }

    const profileMenuRoutes = computed(() => utils.filteredRoutes('inNavMenu'))

    const goToProfile = () => {
      router.push({
        name: 'Profile',
        params: { id: loggedUser.value.uid }
      })
    }

    return {
      profileMenu,
      profileMenuRoutes,
      config2,
      goToProfile,
      logoutClick,
      loggedUser,
      openMenu,
      closeMenu
    }
  },

  methods: {
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
    }
  }
}
</script>

<style></style>
