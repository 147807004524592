<template>
  <app-card v-bind="[basicCardDefaults, $attrs]" class="mx-1 my-2">
    <v-row no-gutters>
      <v-col cols="8" md="6">
        <div class="ml-4 mt-2 text-capitalize">
          <slot name="title"></slot>
        </div>
        <v-card-subtitle class="ml-2 pb-0 text-subtitle-2">
          <slot name="subtitle"></slot>
        </v-card-subtitle>
      </v-col>
      <v-col class="d-flex justify-end align-center mt-2 mr-2 mb-0">
        <div>
          <slot name="info"></slot>
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-card-text class="ma-0 py-0 text-body-2 text-justify">
          <slot />
        </v-card-text>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card-text class="px-1 text-body-2 text-justify">
          <slot name="footer" />
        </v-card-text>
      </v-col>
    </v-row>
  </app-card>
</template>

<script>
import { computed } from '@vue/composition-api'
export default {
  name: 'BasicCard',

  /*  props: {
    loading: {
      type: [String, Boolean],
      default: false
    }
  }, */

  setup() {
    const basicCardDefaults = computed(() => ({
      loading: false
    }))

    return { basicCardDefaults }
  }
}
</script>
