import { ref } from '@vue/composition-api'
import { fbFirestore } from '@/plugins/firebase.js'

const useCollection = (collection) => {
  const error = ref(null)

  const addDoc = async (doc) => {
    error.value = null
    try {
      const res = await fbFirestore.collection(collection).add(doc)
      return res
    } catch (err) {
      console.log(err.message)
      error.value = `Mensagem não enviada: (${err.message})`
    }
  }
  const addUser = async (doc, userId) => {
    error.value = null
    try {
      const res = await fbFirestore.collection(collection).doc(userId).set(doc)
      return res
    } catch (err) {
      console.log(err.message)
      error.value = `Mensagem não enviada: (${err.message})`
    }
  }

  return { error, addDoc, addUser }
}

export default useCollection
