<template>
  <v-progress-linear
    :active="navLoading"
    :indeterminate="true"
    absolute
    bottom
    :color="color"
  />
</template>

<script>
export default {
  name: 'NavLoading',

  props: {
    color: {
      type: String,
      default: 'red'
    }
  },

  computed: {
    navLoading() {
      return this.$store.state.home.navLoading
    }
  }
}
</script>
