<template>
  <div>
    <v-row no-gutters align="center">
      <v-col cols="12" sm="6">
        <basic-card>
          <template #title>Circular Loading</template>
          <v-row no-gutters align="center">
            <v-col cols="6">
              <v-progress-circular
                :rotate="360"
                :size="100"
                :width="15"
                :value="circular"
                color="black"
              >
                {{ circular ? `${circular}%` : '' }}
              </v-progress-circular>
            </v-col>
            <v-col>
              <app-btn
                color="primary"
                :disabled="loadingCircular"
                @click="actionCircular"
                >Apertar</app-btn
              >
            </v-col>
          </v-row>
        </basic-card>
      </v-col>
      <v-col>
        <basic-card>
          <template #title>Linear Loading</template>
          <v-row no-gutters align="center">
            <v-col class="mb-2" cols="12"
              ><v-progress-linear v-model="linear" height="25">
                <strong>{{ linear ? `${Math.ceil(linear)}%` : '' }}</strong>
              </v-progress-linear>
            </v-col>
            <v-col>
              <app-btn
                color="default"
                block
                :disabled="loadingLinear"
                @click="actionLinear"
                >Apertar</app-btn
              ></v-col
            >
          </v-row>
        </basic-card>
      </v-col>
    </v-row>
    <v-row no-gutters align="center">
      <v-col cols="12" sm="6">
        <basic-card>
          <template #title>Nav Bar Loading</template>
          <app-btn
            :color="!navLoading ? 'default' : 'error'"
            block
            @click="setNavLoading(!navLoading)"
            >{{
              !navLoading ? 'Ativar NavBar Loading' : 'Parar NavBar Loading'
            }}</app-btn
          >
        </basic-card>
      </v-col>
      <v-col>
        <basic-card>
          <template #title>Overlay Loading</template>
          <app-btn
            color="default"
            block
            :disabled="overlayLoading"
            @click="setOverlayLoading"
            >Ativar Overlay Loading</app-btn
          >
        </basic-card>
      </v-col>
    </v-row>
    <v-row no-gutters align="center">
      <v-col cols="6">
        <basic-card>
          <template #title>Button with Loading</template>
          <app-btn
            color="default"
            block
            :loading="btnLoading"
            @click="setBtnLoading"
            >Clique</app-btn
          >
        </basic-card>
      </v-col>
      <v-col>
        <basic-card>
          <template #title>Icon Loading</template>
          <div
            v-for="(item, i) in iconLoadingData"
            :key="item.name"
            @click="toggleItem(item)"
          >
            <app-btn
              color="secondary"
              icon
              :loading="iconLoading && i === index"
            >
              <app-icon
                :icon="
                  !item.value
                    ? 'checkbox-blank-outline'
                    : 'checkbox-marked-outline'
                "
              ></app-icon>
            </app-btn>
            {{ item.name }}
            <v-divider v-if="i < iconLoadingData.length - 1"></v-divider>
          </div>
        </basic-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { BasicCard } from '@/components/layout/cards'
export default {
  name: 'Loading',

  components: {
    BasicCard
  },

  data: () => ({
    index: -1,
    btnLoading: false,
    knowledge: 10,
    circular: 0,
    loadingCircular: false,
    btnCircularActive: true,
    linear: 0,
    loadingLinear: false,
    btnLinearActive: true,
    iconLoading: false,
    iconLoadingData: [
      { id: 1, value: false, name: 'Opção 01' },
      { id: 2, value: false, name: 'Opção 02' },
      { id: 3, value: false, name: 'Opção 03' }
    ]
  }),

  computed: {
    ...mapState({
      navLoading: (state) => state.home.navLoading,
      overlayLoading: (state) => state.home.overlayLoading
    })
  },

  methods: {
    ...mapActions('home', ['setNavLoading']),
    async toggleItem(item) {
      const index = this.$utils.getIndex(this.iconLoadingData, item.id)
      this.index = index
      this.iconLoading = true
      const value = !this.iconLoadingData[index].value
      this.$set(this.iconLoadingData, index, {
        ...this.iconLoadingData[index],
        value
      })
      await this.$utils.delay(500)
      this.iconLoading = false
      this.index = -1
    },
    async setBtnLoading() {
      this.btnLoading = true
      await this.$utils.delay(3000)
      this.btnLoading = false
    },
    async setOverlayLoading() {
      this.$store.dispatch('home/setOverlayLoading', true)
      await this.$utils.delay(3000)
      this.$store.dispatch('home/setOverlayLoading', false)
    },
    async actionCircular() {
      this.loadingCircular = true
      this.btnCircularActive = false
      this.circular = 0
      for (let i = 0; i < 10; i++) {
        await this.$utils.delay(1000)
        this.circular += 10
      }
      await this.$utils.delay(3000)
      this.circular = 0
      this.loadingCircular = false
      this.btnCircularActive = true
    },
    async actionLinear() {
      this.loadingLinear = true
      this.btnLinearActive = false
      this.linear = 0
      for (let i = 0; i < 10; i++) {
        await this.$utils.delay(1000)
        this.linear += 10
      }

      this.linear = 0
      this.loadingLinear = false
      this.btnLinearActive = true
    }
  }
}
</script>
<style></style>
