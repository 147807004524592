<template>
  <v-app-bar dense app flat color="default">
    <transition name="wrapper">
      <div v-if="!navSearch" class="d-flex align-center">
        <app-icon
          v-if="!navBack"
          :icon="!drawer ? drawerIcon : drawerCloseIcon"
          @click.stop="toggleDrawer"
        />
        <app-icon
          v-else
          icon="arrow-left"
          @click.stop="$router.go(-1)"
        ></app-icon>

        <v-toolbar-title class="ml-3"> Collapsing Bar </v-toolbar-title>
      </div>
    </transition>

    <app-spacer v-if="!navSearch && $vuetify.breakpoint.xsOnly" id="outer" />

    <slot name="widgetSlot" />

    <app-spacer v-if="!navSearch && $vuetify.breakpoint.xsOnly"></app-spacer>
    <slot
      v-if="$vuetify.breakpoint.xsOnly && !navSearch"
      name="widgetSlotIcon"
    />
    <slot v-if="$vuetify.breakpoint.smAndUp && !navSearch" name="rSlot2" />

    <slot v-if="!navSearch" name="rSlot1" />

    <nav-loading v-if="loading" color="black" />
  </v-app-bar>
</template>

<script>
import { computed } from '@vue/composition-api'
import store from '@/store'
export default {
  name: 'NavBar',
  components: {
    NavLoading: () => import('./NavLoading')
  },

  props: {
    app: {
      type: Boolean,
      default: false
    },
    drawer: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    drawerCloseIcon: {
      type: String,
      default: 'arrow-left'
    },
    drawerIcon: {
      type: String,
      default: 'menu'
    }
  },

  setup(props, { emit }) {
    const toggleDrawer = () => {
      emit('toggleDrawer')
    }

    const navSearch = computed(() => store.state.home.navSearch)

    const navBack = computed(() => {
      return store.state.home.navBack
    })

    return { toggleDrawer, navBack, navSearch }
  }
}
</script>

<style scoped>
.wrapper-enter-active {
  animation: finished 1.5s reverse;
}

@keyframes finished {
  0% {
    opacity: 1;
    top: 0;
  }
  50% {
    opacity: 1;
    top: 0;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    top: -100vh;
  }
}
</style>
