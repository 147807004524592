import { store as auth } from '@/modules/core/auth'
import { store as home } from '@/modules/core/home'

import { store as tasks } from '@/modules/widgets/tasks'
import { store as messages } from '@/modules/widgets/messages'
import { store as blog } from '@/modules/widgets/blog'
import { store as chat } from '@/modules/widgets/chat'
import { store as playlists } from '@/modules/widgets/playlists'
import { store as albums } from '@/modules/widgets/albums'

import { store as examples } from '@/modules/widgets/examples'
export default {
  auth,
  tasks,
  home,
  examples,
  chat,
  blog,
  messages,
  playlists,
  albums
}
