<template>
  <v-bottom-sheet :value="active" @input="close">
    <v-sheet class="text-center" :height="size">
      <app-btn color="error" text block @click="close"
        ><app-icon icon="close"></app-icon
      ></app-btn>
      <v-row no-gutters>
        <v-col cols="12">
          <slot />
        </v-col>
      </v-row>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
import { computed } from '@vue/composition-api'
export default {
  name: 'BottomSheet',

  props: {
    size: {
      type: String,
      default: '400'
    },
    dialog: {
      type: Boolean,
      default: null
    }
  },

  setup(props, { emit }) {
    const active = computed(() => props.dialog)

    const close = () => {
      emit('close')
    }

    return { active, close }
  }
}
</script>

<style></style>
