<template>
  <v-speed-dial
    v-model="isActive"
    fixed
    v-bind="[pos, $attrs]"
    transition="slide-y-reverse-transition"
    v-on="$listeners"
  >
    <template v-slot:activator>
      <app-btn v-model="isActive" fab dark>
        <app-icon :icon="isActive ? 'close' : 'account'"></app-icon>
      </app-btn>
    </template>
    <slot />
  </v-speed-dial>
</template>

<script>
import { ref } from '@vue/composition-api'
import useAppComponents from './useAppComponets'
export default {
  name: 'AppSpeedDial',

  props: {
    position: {
      type: String,
      default: 'right-bottom'
    },
    icon: {
      type: String,
      default: ''
    }
  },

  setup(props) {
    const { fabPosition } = useAppComponents()
    const pos = fabPosition(props.position)
    const isActive = ref(false)

    return { pos, isActive }
  }
}
</script>

<style></style>
