export default [
  {
    path: '/calc',
    name: 'Calc',
    component: () => import(/* webpackChunkName: "calc" */ './views/Calc.vue'),
    meta: {
      title: 'Calculadora',
      inNavWidget: true,
      icon: 'calculator'
    }
  }
]
